<div
    [ngStyle]="{
        'width.px': itemWidth,
        'padding-left.px': itemGap / 2,
        'padding-right.px': itemGap / 2,
    }"
    class="DTX_HEADER_NAV_ITEM"
    #itemEl
>
    @if (showLogonButton) {
        <app-login-button class="login-button"></app-login-button>
    } @else {
        <div>
            <a
                [routerLink]="item.routerLink"
                [fragment]="item.fragment"
                [queryParams]="item.queryParams"
                class="DTX_HEADER_NAV_LINK"
                routerLinkActive="active"
                >{{ item.label }}</a
            >
        </div>
    }
    @if (item != null && item.items?.length) {
        <p-tieredMenu
            [model]="item.items"
            class="DTX_HEADER_NAV_SUBMENU"
            [ngStyle]="{ 'width.px': menuWidth }"
            [ngClass]="{ 'menu-rtl': rtl, show: show }"
            (onShow)="bindMouseover()"
        >
            <ng-template
                pTemplate="item"
                let-item
                let-hasSubmenu="item.items?.length"
            >
                @if (showLogonButton) {
                    <app-login-button class="login-button"></app-login-button>
                } @else if (!item.items?.length) {
                    <a
                        [routerLink]="item.routerLink"
                        [fragment]="item.fragment"
                        [queryParams]="item.queryParams"
                        class="p-menuitem-link"
                        (click)="onClick(item, $event)"
                        >{{ item.label }}</a
                    >
                } @else {
                    <div class="p-menuitem-link has-children">
                        @if (rtl) {
                            <span class="fa fa-chevron-left tw-mr-4"></span>
                        }
                        <a
                            [routerLink]="item.routerLink"
                            [fragment]="item.fragment"
                            [queryParams]="item.queryParams"
                            (click)="onClick(item, $event)"
                            class="tw-mr-auto"
                            >{{ item.label }}</a
                        >
                        @if (!rtl) {
                            <span class="fa fa-chevron-right tw-ml-2"></span>
                        }
                    </div>
                }
            </ng-template>
        </p-tieredMenu>
    }
    <!-- } -->
</div>
