<ng-template #rowTemplate>
    <div class="DTX_CONTENT_ROW row m-0 {{ rowTypeClasses(row.Style ?? '') }} {{ row.CssClasses }}">
        <!-- Below inner ngFor loop is for columns which our main schemas and inside that we will call content-items components -->
        @for (column of row.Columns; track column) {
            <div class="{{ column.CssClasses }} {{ this.getBsColClasses(column) }} DTX_CONTENT_COL">
                @if (getCardLink($any(column?.Options))) {
                    <a [href]="getCardLinkUrl($any(column.Options))" class="DTX_CONTENT_COL_LINK">
                        <div class="{{ rowTypeColClasses(row.Style ?? '', getCardLink($any(column?.Options))) }} DTX_CONTENT_COL_HOLDER">
                            <app-col [col]="column" [row]="row" class="DTX_CONTENT_APP_COL" />
                        </div>
                    </a>
                } @else {
                    <div class="{{ rowTypeColClasses(row.Style ?? '', getCardLink($any(column?.Options))) }} DTX_CONTENT_COL_HOLDER">
                        <app-col [col]="column" [row]="row" class="DTX_CONTENT_APP_COL" />
                    </div>
                }
            </div>
        }
    </div>
</ng-template>

@if (getCardLink($any(row.Options))) {
    <a [href]="getCardLinkUrl($any(row.Options))" class="DTX_CONTENT_ROW_LINK">
        <ng-container *ngTemplateOutlet="rowTemplate" />
    </a>
} @else {
    <ng-container *ngTemplateOutlet="rowTemplate" />
}
