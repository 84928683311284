<div class="DTX_CONTENT_BLOCK_HOLDER" #contentEl>
    <a class="DTX_CONTENT_ANCHOR" id="DTX_{{ contentId }}"></a>
    @if (StyleCode === 'Coloured shapes') {
        <app-coloured-shapes />
    }
    @if (content) {
        <div
            [ngClass]="newsletterStyling ? 'DTX_CONTENT_BLOCK' : 'DTX_CONTENT_BLOCK ' + content.CssClasses"
        >
            @if (content.Anchor) {
                <a class="DTX_CONTENT_ANCHOR" [id]="content.Anchor"></a>
            }
            @if (content.Title) {
                <app-title [contentTitle]="content.Title" />
            }
            @for (row of content.Rows; track row) {
                <div>
                    <app-row [row]="row" />
                </div>
            }
            @if (newsletterStyling && content.Published) {
                <div class="row">
                    <p class="DTX_CONTENT_PUBLISHED tw-p-10 tw-text-sm">
                        <i class="fa fa-calendar-alt"></i>
                        {{ getPublishDate(content.Published) }}
                    </p>
                </div>
            }
        </div>
    }
</div>
