<div class="DTX_CONTENT_TYPE_SUBSCRIBE !tw-p-4 {{ subscribeDetails.CssClasses }}">
    <div class="DTX_CONTENT_TYPE_SUBSCRIBE_CONTENT tw-mx-auto">
        @if (subscribeDetails.HeadlineText) {
            <h2>{{ subscribeDetails.HeadlineText }}</h2>
        }
        @if (subscribeDetails.BodyText) {
            <p>{{ subscribeDetails.BodyText }}</p>
        }
    </div>
    <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit()" class="tw-max-w-[25rem] md:tw-w-[25rem] tw-mx-auto">
        <div class="DTX_CONTENT_TYPE_SUBSCRIBE_INPUTS tw-flex tw-flex-col tw-gap-4">
            <div class="tw-flex tw-gap-4">
                @if (subscribeDetails.ShowFirstName) {
                    <div class="tw-flex-1">
                        <input
                            id="subscribeFirstName"
                            type="text"
                            [formControl]="subscribeForm.controls.subscribeFirstName"
                            [placeholder]="subscribeDetails.PlaceholderFirstName ?? ''"
                            [attr.aria-label]="subscribeDetails.PlaceholderFirstName ?? ''"
                            class="form-control DTX_CONTENT_TYPE_SUBSCRIBE_INPUTS_FIRST_NAME"
                            minlength="1"
                        />
                    </div>
                }
                @if (subscribeDetails.ShowLastName) {
                    <div class="tw-flex-1">
                        <input
                            id="subscribeLastName"
                            type="text"
                            [formControl]="subscribeForm.controls.subscribeLastName"
                            [placeholder]="subscribeDetails.PlaceholderLastName ?? ''"
                            [attr.aria-label]="subscribeDetails.PlaceholderLastName ?? ''"
                            class="form-control DTX_CONTENT_TYPE_SUBSCRIBE_INPUTS_LAST_NAME"
                            minlength="1"
                        />
                    </div>
                }
            </div>
            <div class="tw-flex tw-gap-3">
                <div class="tw-flex-1">
                    <input
                        id="subscribeEmail"
                        type="email"
                        [formControl]="subscribeForm.controls.subscribeEmail"
                        [placeholder]="subscribeDetails.PlaceholderEmail ?? ''"
                        [attr.aria-label]="subscribeDetails.PlaceholderEmail ?? ''"
                        class="form-control DTX_CONTENT_TYPE_SUBSCRIBE_INPUTS_EMAIL"
                        minlength="1"
                    />
                </div>
                @if (subscribeDetails.ShowLanguage) {
                    <div class="tw-flex-1">
                        <select
                            id="subscribeLanguage"
                            [formControl]="subscribeForm.controls.subscribeLanguage"
                            class="form-select DTX_CONTENT_TYPE_SUBSCRIBE_INPUTS_LANGUAGE"
                            aria-label="Select Preferred Language"
                        >
                            @for (language of languages; track language) {
                                <option value="{{ language.code }}" [selected]="language.code === appStore.$language().code">
                                    {{ language.name }}
                                </option>
                            }
                        </select>
                    </div>
                }
            </div>
        </div>
        <div class="DTX_CONTENT_TYPE_SUBSCRIBE_SUBMIT tw-mt-4">
            <button pRipple type="submit" class="btn btn-primary w-100" [disabled]="!subscribeForm.valid || $loading()">
                {{ subscribeDetails.ButtonText }}
            </button>
        </div>
    </form>
</div>
