import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { AnimationDirective } from '../../directives/animations.directive';
import { EmbedPipe } from '../../pipes/embed-pipe';
import { AnchorToRouterService } from '../../services/anchor-to-router.service';

@Component({
    selector: 'app-rtext',
    templateUrl: './rtext.component.html',
    styleUrls: ['./rtext.component.scss'],
    standalone: true,
    imports: [EmbedPipe, AnimationDirective],
})
export class RtextComponent implements AfterViewInit {
  @Input() embeddedText!: any;

  constructor(private el: ElementRef, private anchorToRouterService: AnchorToRouterService) {}

  getRichText() {
    let text;
    try {
      text = this.embeddedText.TextString;
    } catch (e) {
      console.log(e);
    }
    return text;
  }

  ngAfterViewInit() {
    this.anchorToRouterService.convertAnchorsToRouterLinks(this.el);
  }
}
