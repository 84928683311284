<!-- <div>
  <select
    #language
    (change)="onChange(language.value)"
    [(ngModel)]="selectedLanguageCode"
    class="form-select mx-3 w-auto"
    aria-label="Language Select"
  >
    @for (language of languages; track language) {
      <option value="{{ language.code }}" [selected]="language.code === defaultLanguage.code">
        {{ language.name }}
      </option>
    }
  </select>
</div> -->
<p-dropdown
    #language
    [options]="languages"
    (onChange)="onChange($event.value)"
    [(ngModel)]="selectedLanguageCode"
    ariaLabel="Language Select"
    optionValue="code"
    optionLabel="name"
    [filter]="false"
    styleClass="DTX_LANGUAGE"
    scrollHeight="400px"
>
</p-dropdown>
