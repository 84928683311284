@if (!articleDetails) {
  <p-progressSpinner />
}
@if (articleDetails) {
  <div class="modal-header">
    <h5 class="modal-title DTX_CONTENT_ARTICLE_MODAL_TITLE" id="staticBackdropLabel">
      {{ articleDetails.Title }}
    </h5>
    <button
      (click)="onClose()"
      aria-label="Close"
      class="btn-close DTX_CONTENT_ARTICLE_MODAL_CLOSE_BTN"
      data-bs-dismiss="modal"
      type="button"
    ></button>
  </div>
}
<div class="modal-body DTX_CONTENT_ARTICLE_MODAL">
  <app-content [content]="articleDetails" class="DTX_CONTENT_ARTICLE_MODAL_CONTENT" />
</div>
