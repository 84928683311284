import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dtxChildAnimations]',
  standalone: true,
})
export class ChildAnimationDirective implements OnInit, OnDestroy {
    private animationElements:any;
    private animationRunAlready:boolean = false;
    private observer: IntersectionObserver | null = null;
    constructor(
        private renderer: Renderer2,
        private el: ElementRef,
      ) {
      }

      ngOnInit(): void {
        this.createObserver(this);
      }

      ngOnDestroy(): void {
        if (this.observer != null) {
          this.observer.disconnect();
          this.observer = null;
        }
      }

      createObserver(baseObj:ChildAnimationDirective) {
        if (typeof(IntersectionObserver) != 'undefined') {
          baseObj.animationElements = baseObj.el.nativeElement.querySelectorAll('[class^="Animate"], [class*=" Animate"]');
          baseObj.observer = new IntersectionObserver((entries, observer) => {
              entries.forEach(entry => {
                if (entry.isIntersecting || this.animationRunAlready) {
                  entry.target.classList.add('StartAnimation');
                  baseObj.animationRunAlready = true;
                  observer.unobserve(entry.target);
                }
              });
            });

            baseObj.animationElements.forEach((element:any) => {
              if (baseObj.observer != null) {
                baseObj.observer.observe(element);
              }
            });
      }
      }
}