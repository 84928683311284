import { Component, Input } from '@angular/core';
import { DynamicCssRulesService } from '../../services/dynamic-css-rules.service';
import { ContentItemsComponent } from '../content-items/content-items.component';

@Component({
  selector: 'app-col',
  templateUrl: './col.component.html',
  styleUrls: ['./col.component.scss'],
  standalone: true,
  imports: [ContentItemsComponent],
})
export class ColComponent {
  @Input() col!: ColumnDto;
  @Input() row!: RowDto;

  constructor(private dynamicCssRulesService: DynamicCssRulesService) {}

  getColSize() {
    return this.dynamicCssRulesService.getBsSizes(this.row, this.col);
  }
}
