import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Subject, takeUntil } from 'rxjs';
import { PageService } from 'src/app/shared/services/page.service';
import { Route, RouteQuery, RouteStore } from 'src/app/store';
import { ContentComponent } from '../content/content.component';

@Component({
  selector: 'app-articles-modal',
  templateUrl: './articles-modal.component.html',
  styleUrls: ['./articles-modal.component.scss'],
  providers: [RouteQuery, RouteStore],
  standalone: true,
  imports: [ProgressSpinnerModule, forwardRef(() => ContentComponent)],
})
export class ArticlesModalComponent implements OnInit {
  @Input() articleId!: string;
  selectedLanguageCode = '';
  articleDetails!: PageContentDto;
  title!: string;
  protected unsubscribe = new Subject<void>();

  constructor(
    private modalService: NgbActiveModal,
    private pageService: PageService,
    private routeQuery: RouteQuery,
  ) {
    this.routeQuery.route$.pipe(takeUntil(this.unsubscribe)).subscribe((route: Route) => {
      this.selectedLanguageCode = route.language.code;
    });
  }

  ngOnInit(): void {
    this.pageService.getArticleById(document.location.hostname, this.selectedLanguageCode, this.articleId).then((response: any) => {
      this.articleDetails = response;
      this.title = this.articleDetails.Title ?? '';
    });
  }

  onClose() {
    this.modalService.close(true);
  }
}
