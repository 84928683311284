// A pipe to translate the text
// This is used for the text that should automatically change when the systemStrings are changed.
import { Pipe, PipeTransform, Signal } from '@angular/core';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'translateSignal',
  standalone: true,
})
export class TranslateSignalPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string | null, defaultValue?: string): Signal<string> {
    return this.translateService.translateSignal(value, defaultValue);
  }
}
