import { Component, Input } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { RowComponent } from '../row/row.component';
import { RtextComponent } from '../rtext/rtext.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RtextComponent, MenuComponent, RowComponent],
})
export class FooterComponent {
  @Input() footerContent!: Footer;
}
