import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStore }    from 'src/app/store/app.store';
import { environment } from '../../../environment/environment';

type PublicSubscribeInput = {
  FirstName: string;
  LastName: string;
  Email: string;
  LanguageCode: string;
  MailingGroupIds?: number[];
};
@Injectable({
  providedIn: 'root',
})
export class SubscribeService {
  constructor(
    private http: HttpClient,
    private appStore: AppStore,
  ) {}

  publicSubscribe(input: PublicSubscribeInput) {
    return this.http.post(`${environment.api}groups/api/${this.appStore.$appName()}/Subscriptions/PublicSubscribe`, input);
  }
}
