import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { effect, Injectable, signal } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { on } from 'node:events';
import { firstValueFrom } from 'rxjs';
import { AppStore } from 'src/app/store/app.store';
import { environment } from 'src/environment/environment';
import { NotificationData } from 'src/types/push';
import { SimpleModalComponent } from '../components/simple-modal/simple-modal.component';

export const PUSH_NOTIFICATION_SUBSCRIBED = 'push-notification-subscribed';
export const PUSH_NOTIFICATION_CONFIRMATION = 'push-notification-confirmation';

//TODO once we know we are subscribed we can subscribe to clicks on messages, and display on message - so maybe after
//first click show full message, if we have a long message, in a dialog or similar?  
@Injectable({
  providedIn: 'root',
})
export class PushNotificationService{
  $subscription = signal<PushSubscription | null>(null);
  VAPIDPublicKey: string;
  apiAddress: string;

  constructor(
    private swPush: SwPush,
    private http: HttpClient,
    private appStore: AppStore,
    private modalService: NgbModal
  ) {
    this.VAPIDPublicKey = environment.VAPIDPublicKey;
    // Middleware is a good proxy for most of APIM (don't know why the name got changed to API its a bit confusing) content, but not for push notification subscriptions
    this.apiAddress =  environment.api.includes('localhost:7196') ? environment.cmsProxyTarget?.substring(0, environment.cmsProxyTarget.length - 4) ?? "" : environment.api;
    effect(() => {
      if (
        typeof window !== 'undefined' &&
        this.appStore.$isLoggedIn() &&
        this.appStore.$appName() &&
        Notification.permission === 'granted' &&
        !this.alreadySubscribed()
      ) {
        this.subscribe();
      }
    });

    this.swPush.notificationClicks.subscribe(({action, notification}) => {
      console.log(notification);
      var notificationBody = notification.data as NotificationData
      if(notificationBody != null && (notificationBody.imageUrl != null || notificationBody.longMessage != null)){
        const modalRef = this.modalService.open(SimpleModalComponent);
        modalRef.componentInstance.title = notification.title;
        modalRef.componentInstance.text = notificationBody.longMessage;
        modalRef.componentInstance.imageUrl = notificationBody.imageUrl;
      }
  });
  }

  // * This method is called when the user clicks the "Enable Push Notifications" button
  // * Must throw an error if something goes wrong
  async subscribe() {
    if (typeof window === 'undefined' || window.location.pathname === '/') {
      return;
    }
    // * If the user didn't confirm the push notification, don't subscribe
    if (localStorage.getItem(PUSH_NOTIFICATION_CONFIRMATION) !== 'true') {
      return;
    }

    const sub = await this.swPush.requestSubscription({
      serverPublicKey: this.VAPIDPublicKey,
    });

    this.$subscription.set(sub);

    const appName = this.appStore.$appName();
    await this.sendSubscriptionToTheServer(sub, appName);
    localStorage.setItem(PUSH_NOTIFICATION_SUBSCRIBED, 'true');
  }

  async sendSubscriptionToTheServer(subscription: PushSubscription, appName: string) {
    const subJson = subscription.toJSON();
    const { endpoint, keys: { auth, p256dh } = {} } = subJson;
    console.log('Push Notification Subscription:', { p256dh, auth });
    try {      
      await firstValueFrom(
        this.http.post(
          `${this.apiAddress}api/PushSubscription`,
          {
            p256dh,
            auth,
            endpoint,
            appName,
          },
          { responseType: 'text' },
        ),
      );
    } catch (err: any) {
      // * If the server responds with a 400 status code, it means the token is invalid
      if ((err as HttpErrorResponse).status === 400) {
        this.appStore.token = '';
      }
      throw err;
    }
  }

  unsubscribe() {
    this.$subscription()?.unsubscribe();
    this.$subscription.set(null);
  }

  alreadySubscribed() {
    return localStorage.getItem(PUSH_NOTIFICATION_SUBSCRIBED) === 'true';
  }
}
