import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DynamicCssRulesService {
  getBsSizes(rowObj: RowDto, colObj: ColumnDto): { desktop: number; mobile: number } {
    const { NumberOfCols, NumberOfColsMobile, ColumnWidth, ColumnWidthMobile } = this.getDefaultOrNumber(rowObj, colObj);

    //default behavior
    let desktop = Math.max(12 / rowObj.Columns.length, 3);
    let mobile = 12;

    //if there is a number, use that instead
    if (NumberOfCols) {
      desktop = 12 / NumberOfCols;
    }

    if (NumberOfColsMobile) {
      mobile = 12 / NumberOfColsMobile;
    }

    //if the column width is set, use that instead
    if (ColumnWidth) {
      desktop = ColumnWidth;
    }

    if (ColumnWidthMobile) {
      mobile = ColumnWidthMobile;
    }

    //Width cant be more than 12
    desktop = Math.min(desktop, 12);
    mobile = Math.min(mobile, 12);

    return { desktop, mobile };
  }

  private getDefaultOrNumber(rowObj: RowDto, colObj: ColumnDto) {
    return {
      NumberOfCols: this.parseNumberDefault(rowObj.NumberOfCols),
      NumberOfColsMobile: this.parseNumberDefault(rowObj.NumberOfColsMobile),
      ColumnWidth: this.parseNumberDefault(colObj.ColumnWidth),
      ColumnWidthMobile: this.parseNumberDefault(colObj.ColumnWidthMobile),
    };
  }

  private parseNumberDefault(number: string | null): number | false {
    return number === 'Default' || number === null ? false : parseInt(number);
  }
}
