import { Component, Input } from '@angular/core';
import { CachedSrcDirective } from '../../directives/cached-src.directive';

type EmbedVideo = IPageContentComponent;

@Component({
    selector: 'app-embed-video',
    templateUrl: './embed-video.component.html',
    styleUrls: ['./embed-video.component.scss'],
    standalone: true,
    imports: [CachedSrcDirective],
})
export class EmbedVideoComponent {
  //TODO Get height and width from squidex
  @Input() videoLink!: EmbedVideo;

  getVideoLink() {
    if (this.videoLink.Url) {
      const url = new URL(this.videoLink.Url);

      if (this.videoLink.AutoPlay) {
        url.searchParams.set('autoplay', '1');
        url.searchParams.set('mute', '1');
      }

      url.searchParams.set('rel', '0');

      return url.toString();
    } else {
      return '';
    }
  }

  getPadding(): string {
    const paddingTop =
      this.videoLink.Width !== undefined && this.videoLink.Height !== undefined
        ? (this.videoLink?.Height / this.videoLink?.Width) * 100
        : 0;
    return paddingTop.toString() + '%';
  }

  getAllowList(): string {
    return 'autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen';
  }
}
