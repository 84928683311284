import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { AnchorToRouterService } from '../../services/anchor-to-router.service';
import { PageTypeService } from '../../services/page-type.service';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  standalone: true,
})
export class ImagesComponent implements AfterViewInit {
  @Input() Image: any;
  @Input() colSizes?: { desktop: number; mobile: number };
  @Input() SqWidth?: number;
  @Input() urlOverride?: string;
  private readonly pageType: boolean;
  private readonly multiplier: number;

  constructor(
    private el: ElementRef,
    private anchorToRouterService: AnchorToRouterService,
    private pageTypeService: PageTypeService,
  ) {
    this.pageType = this.pageTypeService.getPageType();
    this.multiplier = this.pageType ? 100 : 150;
  }

  ngAfterViewInit() {
    this.anchorToRouterService.convertAnchorsToRouterLinks(this.el);
  }

  imageSrc(): string {
    const url = new URL(this.Image.ImageUrl);
    url.searchParams.set('auto', 'true');
    // url.searchParams.set("format", "WebP");
    url.searchParams.set('cache', '8640000');

    if (this.SqWidth) {
      url.searchParams.set('width', String(this.SqWidth));
    } else if (this?.colSizes?.desktop && this?.colSizes?.mobile) {
      if (this.colSizes.mobile > 6) {
        this.colSizes.mobile = 6;
      }
      url.searchParams.set('width', String(Math.max(this.colSizes.desktop, this.colSizes.mobile) * this.multiplier));
    }

    return url.toString();
  }

  imageAlt(): string {
    return this.Image.AltText;
  }

  imageUrl(): string {
    if (this.urlOverride) {
      return this.urlOverride;
    }
    return this.Image.Url === null ? '#' : this.Image.Url;
  }

  alignClass(): string {
    return `DTX_IMAGE_ALIGN_H_${this.Image.Align?.toUpperCase()} DTX_IMAGE_ALIGN_V_${this.Image.AlignVertically?.toUpperCase()}`;
  }

  anchorTag(e: Event) {
    if (this.Image.Url === null) {
      e.preventDefault();
    }
  }

  hasUrl(): boolean {
    return (this.Image.Url !== null && this.Image.Url !== '') || this.urlOverride !== undefined;
  }
}
