import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { SetBackgroundImageDirective } from '../../directives/set-background-image.directive';
import { EmbedPipe } from '../../pipes/embed-pipe';
import { ImagesComponent } from '../images/images.component';

enum CardTypes {
  flip = 'flip',
  reveal = 'reveal',
}

@Component({
  selector: 'flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [ImagesComponent, SetBackgroundImageDirective, CommonModule, EmbedPipe],
})
export class FlipCardComponent {
  @Input({
    transform: (value: IPageContentComponent): FlipCardDto =>
      value as unknown as FlipCardDto,
  })
  flipCard!: FlipCardDto;

  constructor() {}

  get isSlide(): boolean {
    return this.flipCard.CardType === CardTypes.reveal;
  }
}
