<div class="DTX_CONTENT_TYPE_IMAGE_ALIGN {{ alignClass() }}">
  @if (hasUrl()) {
    <a (click)="anchorTag($event)" class="DTX_CONTENT_TYPE_IMAGE_ANCHOR" [href]="imageUrl()">
      <img alt="{{ imageAlt() }}" class="DTX_CONTENT_TYPE_IMAGE {{ Image.CssClasses }}" src="{{ imageSrc() }}" />
    </a>
  }
  @if (!hasUrl()) {
    <img alt="{{ imageAlt() }}" class="DTX_CONTENT_TYPE_IMAGE {{ Image.CssClasses }}" src="{{ imageSrc() }}" />
  }
</div>
