<div class="DTX_CONTENT_TYPE_EMBEDDED_VIDEO" style="padding-top: {{ getPadding() }};">
  <!--  https://angular.io/errors/NG0910 as to why this is as it is-->
  @if (videoLink.EnableFullscreen) {
    <iframe
      [cachedSrc]="getVideoLink()"
      allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
      allowFullscreen
      class="DTX_CONTENT_TYPE_EMBEDDED_VIDEO_IFRAME {{ videoLink.CssClasses }}"
      height="{{ videoLink.Height ?? '315' }}"
      title="{{ videoLink.Name ?? 'Video' }}"
      width="{{ videoLink.Width ?? '560' }}"
    ></iframe>
  }
  @if (!videoLink.EnableFullscreen) {
    <iframe
      [cachedSrc]="getVideoLink()"
      allow="{{ getAllowList() }}"
      class="DTX_CONTENT_TYPE_EMBEDDED_VIDEO_IFRAME {{ videoLink.CssClasses }}"
      height="{{ videoLink.Height ?? '315' }}"
      title="{{ videoLink.Name ?? 'Video' }}"
      width="{{ videoLink.Width ?? '560' }}"
    ></iframe>
  }
</div>
