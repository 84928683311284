// Directive which add "sticky-header" to the element when the page is scrolled
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appStickyHeader]',
  standalone: true,
})
export class StickyHeaderDirective {
  @Input() threshold = 100;
  @Input() stickyClassName = 'DTX_HEADER_STICKY';

  hasStickyClass = false;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (window.scrollY > this.threshold) {
      if (!this.hasStickyClass) {
        this.renderer.addClass(this.el.nativeElement, this.stickyClassName);
        this.hasStickyClass = true;
      }
    } else {
      if (this.hasStickyClass) {
        this.renderer.removeClass(this.el.nativeElement, this.stickyClassName);
        this.hasStickyClass = false;
      }
    }
  }
}
