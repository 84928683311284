import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateSignalPipe } from '../../pipes/translate-signal.pipe';

@Component(
    {
        selector: "simple-modal",
        templateUrl: "./simple-modal.component.html",
        imports: [TranslateSignalPipe],
        standalone: true
    })
export class SimpleModalComponent {
    @Input() title: string = "";
    @Input() text: string = "";
    @Input() imageUrl: string = "";

    constructor(public activeModal: NgbActiveModal){

    }

    close() {
        this.activeModal.close();
    }
}