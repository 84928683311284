import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from 'primeng/button';
import Swiper from 'swiper';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [ButtonModule]
})
export class GalleryComponent {
  @Input({ transform: (value: IPageContentComponent): GalleryDto => value as unknown as GalleryDto }) gallery!: GalleryDto;
  enlargeImages: Image[] = [];
  displayIndex: number = 0;
  openedImage?: Image;
  isFullDisplay = false;

  @ViewChild('swiper') swiperEl?: ElementRef<{ swiper: Swiper }>;

  constructor(private modalService: NgbModal) {}

  openImage(content: any, image: Image) {
    if (!this.gallery) return;
    this.openedImage = image;
    if (this.gallery.CssClasses && this.gallery.CssClasses.includes('DTX-GALLERY-MODAL')) {
      this.enlargeImages = this.gallery.Images;
      this.modalService.open(content, { scrollable: true, size: 'xl' });
    } else {
      this.isFullDisplay = true;
      this.displayIndex = this.gallery.Images.indexOf(image);
    }
    this.swiperEl?.nativeElement?.swiper?.autoplay?.pause();
  }

  hide() {
    this.isFullDisplay = false;
    this.swiperEl?.nativeElement?.swiper?.autoplay?.resume();
  }

  onClose() {
    this.modalService.dismissAll();
    this.enlargeImages = [];
    this.swiperEl?.nativeElement?.swiper?.autoplay?.resume();
  }

  getSrc(ImageUrl: string) {
    const url = new URL(ImageUrl);
    url.searchParams.set('auto', 'true');
    // url.searchParams.set("format", "WebP");
    url.searchParams.set('cache', '8640000');
    url.searchParams.set('width', '2560');
    return url.toString();
  }

  displayLeft(event: MouseEvent) {
    event.stopPropagation();
    this.displayIndex = (this.displayIndex - 1 + this.gallery.Images.length) % this.gallery.Images.length;
  }

  displayRight(event: MouseEvent) {
    event.stopPropagation();
    this.displayIndex = (this.displayIndex + 1) % this.gallery.Images.length;
  }
}
