<div
    class="{{ banners.CssClasses }} DTX_HEADER_BANNER {{
        headerType === 'Hamburger' ? 'DTX_HEADER_BANNER_PAD' : ''
    }}"
>
    <p-carousel
        ngSkipHydration
        [value]="images"
        [numVisible]="1"
        [numScroll]="1"
        [autoplayInterval]="5000"
        [circular]="images.length > 1"
        [showNavigators]="false"
        [showIndicators]="false"
    >
        <ng-template let-bannerImages pTemplate="item">
            <div class="h-100">
                @if (hasUrl(bannerImages.Url)) {
                    <a
                        (click)="anchorTag($event, bannerImages.Url)"
                        class="DTX_HEADER_BANNER_IMAGE_ANCHOR"
                        href="{{ imageUrl(bannerImages.Url) }}"
                    >
                        <img
                            alt="{{ bannerImages.AltText }}"
                            class="{{
                                bannerImages.CssClasses
                            }} DTX_HEADER_BANNER_IMAGE"
                            src="{{ getSrc(bannerImages.ImageUrl) }}"
                        />
                    </a>
                }
                @if (!hasUrl(bannerImages.Url)) {
                    <img
                        alt="{{ bannerImages.AltText }}"
                        class="{{
                            bannerImages.CssClasses
                        }} DTX_HEADER_BANNER_IMAGE"
                        src="{{ getSrc(bannerImages.ImageUrl) }}"
                    />
                }
            </div>
        </ng-template>
    </p-carousel>
</div>
