<div (click)="openPopUp()" class="DTX_FULL_CONTENT_CARD_DESIGN">
    <img alt="Article Image" class="DTX_CONTENT_TYPE_DYNAMIC_ARTICLE_IMAGE" [src]="getSrc(dynamicArticlesDetails?.IndexImage?.ImageUrl)" />
    <h4 class="DTX_CONTENT_TYPE_DYNAMIC_ARTICLE_TITLE">
        {{ dynamicArticlesDetails.Title }}
    </h4>
    <p class="DTX_CONTENT_TYPE_DYNAMIC_ARTICLE_DESCRIPTION">
        {{ dynamicArticlesDetails.ArticleDescription }}
    </p>
    <p class="DTX_CONTENT_TYPE_DYNAMIC_ARTICLE_PUBLISHED">
        {{ formatDate(dynamicArticlesDetails.Published) }}
    </p>
</div>
