import { NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { AnchorToRouterService } from '../../services/anchor-to-router.service';
import { DynamicCssRulesService } from '../../services/dynamic-css-rules.service';
import { ColComponent } from '../col/col.component';

@Component({
  selector: 'app-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
  standalone: true,
  imports: [ColComponent, NgTemplateOutlet],
})
export class RowComponent implements AfterViewInit {
  @Input() row!: RowDto;

  constructor(
    private el: ElementRef,
    private dynamicCssRulesService: DynamicCssRulesService,
    private anchorToRouterService: AnchorToRouterService,
  ) {}

  ngAfterViewInit() {
    this.anchorToRouterService.convertAnchorsToRouterLinks(this.el);
  }

  rowTypeClasses(Style: string) {
    switch (Style) {
      case 'Card':
        return 'DTX_CONTENT_CARDS';
      default:
        return 'DTX_CONTENT_DEFAULT_STYLES';
    }
  }

  getCardLinkUrl(Options: IOtherOptions | ConsumerOptions[] | undefined): string | false {
    const cardLink = this.getCardLink(Options);
    return cardLink ? cardLink.Url : false;
  }

  getCardLink(Options: IOtherOptions | ConsumerOptions[] | undefined): IOtherOptionsCardLink | false {
    return (Options?.find(option => option.$type === 'DTO.ConsumerDTO.Components.CardLink, DTO') as IOtherOptionsCardLink) ?? false;
  }

  getColSize(col: ColumnDto) {
    return this.dynamicCssRulesService.getBsSizes(this.row, col);
  }

  getBsColClasses(col: ColumnDto): string {
    const { mobile, desktop } = this.getColSize(col);

    return `
    col-${mobile}
    col-sm-${mobile}
    col-md-${desktop}
    `;
  }

  rowTypeColClasses(Style: string, HasCardLink: IOtherOptionsCardLink | false) {
    let classes = '';
    if (HasCardLink !== false) {
      classes += 'DTX_HAS_LINK_TO ';
    }

    switch (Style) {
      case 'Cards':
        classes += 'DTX_CONTENT_CARDS';
        break;
      case 'Tiles':
        classes += 'DTX_CONTENT_TILE';
        break;
      default:
        classes += 'DTX_COLUMN_CARD_DEFAULT_DESIGN';
        break;
    }

    return classes;
  }
}
