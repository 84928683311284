import { isPlatformBrowser } from '@angular/common';
import { afterRender, Component, Inject, OnInit, PLATFORM_ID, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateSignalPipe } from 'src/app/shared/pipes/translate-signal.pipe';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AppStore } from 'src/app/store/app.store';

@Component({
  selector: 'app-login-button',
  standalone: true,
  imports: [TranslatePipe, TranslateSignalPipe],
  templateUrl: './login-button.component.html',
  styleUrl: './login-button.component.scss',
  providers: [DialogService],
})
export class LoginButtonComponent implements OnInit {
  ref: DynamicDialogRef | undefined;
  $isLoggedIn: Signal<boolean> | undefined;

  constructor(
    private appStore: AppStore,
    private messageService: MessageService,
    private router: Router,
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId:Object
  ) {
    
  }
  ngOnInit(): void {
      if(isPlatformBrowser(this.platformId)){
        this.$isLoggedIn = this.appStore.$isLoggedIn;
      }    
  }

  logout() {
    this.appStore.token = '';
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.translate('Logout'),
      detail: this.translateService.translate('LoggedOutText', 'You have been logged out'),
    });
  }

  showLogin(): void {
    this.router.navigate([], { queryParams: { loginModal: '1' }, queryParamsHandling: 'merge' });
  }
}
