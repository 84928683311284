<div class="modal-header">
    <h4 class="modal-title DTX_CONTENT_NOTIFICATION_MODAL_TITLE">{{title}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body DTX_CONTENT_NOTIFICATION_MODAL">
    <div class="DTX_CONTENT_NOTIFICATION_MODAL_CONTENT">
        <p [style.white-space]="'pre-line'">{{text}}</p>
        @if("imageUrl != null && imageUrl != ''"){
            <img [src]="imageUrl" alt="Notification image" class="img-fluid">
        }        
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">{{ ('Close' | translateSignal)() }}</button>
</div>