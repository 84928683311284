import { Component, Input, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { AppStore } from 'src/app/store/app.store';
import { defaultLanguage, languages } from 'src/constants/languages.constants';
import { SubscribeService } from '../../services/subscribe.service';
import { TranslateService } from '../../services/translate.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ButtonModule, RippleModule],
})
export class SubscribeComponent {
  @Input() subscribeDetails!: IPageContentComponent;
  subscribeForm = new FormGroup({
    subscribeEmail: new FormControl(''),
    subscribeFirstName: new FormControl(''),
    subscribeLastName: new FormControl(''),
    subscribeLanguage: new FormControl(this.appStore.$language().code),
  });
  protected readonly languages = languages;
  protected readonly defaultLanguage = defaultLanguage;
  $loading = signal(false);

  constructor(
    public appStore: AppStore,
    private subscribeService: SubscribeService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  async onSubmit() {
    const subscribeDetails = this.subscribeForm.value;
    if (!subscribeDetails.subscribeEmail) {
      return;
    }
    this.$loading.set(true);

    this.subscribeService
      .publicSubscribe({
        FirstName: subscribeDetails.subscribeFirstName ?? '',
        LastName: subscribeDetails.subscribeLastName ?? '',
        Email: subscribeDetails.subscribeEmail,
        LanguageCode: subscribeDetails.subscribeLanguage ?? this.appStore.$language().code ?? defaultLanguage,
        MailingGroupIds: this.subscribeDetails.Groups,
      })
      .subscribe({
        next: () => {
          this.$loading.set(false);
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.translate('Success'),
            detail: this.subscribeDetails.MessageSuccess ?? this.translateService.translate('SubscribeSuccess'),
          });
        },
        error: e => {
          this.$loading.set(false);
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.translate('Error'),
            detail: this.subscribeDetails.MessageError ?? this.translateService.translate('SubscribeError'),
          });
          console.error(e);
        },
      });
  }
}
