<div class="mainMenu topNav">
    <p-sidebar
        #sidebar
        [(visible)]="sidebarVisible"
        [showCloseIcon]="false"
        [blockScroll]="false"
        position="right"
        appendTo="body"
        styleClass="DTX_MENU_SIDEBAR"
        (onHide)="sidebar.destroyModal();"
    >
        <ng-template pTemplate="headless">
            <div id="menuLinks">
                <div class="text-end pe-4 pb-4">
                    <button
                        (click)="onNavigationMenu()"
                        class="icon search-menu-btn close-icon-btn"
                        type="button"
                        aria-label="Close Main Menu"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </div>
                <ng-template #recursiveList let-mainMenu>
                    @for (item of mainMenu; track item; let i = $index) {
                        <div>
                            <div class="dropdown-item" [style]="'--data: ' + ((item.count ?? 0) + 1) + 'rem'">
                                <a
                                    [routerLink]="item?.Slug"
                                    (click)="item?.Slug ? onRouting(item, $event) : expandSubMenu(item)"
                                    class="full-width"
                                >
                                    {{ item.Text }}
                                </a>
                                <span (click)="expandSubMenu(item)" class="spacer"> </span>
                                @if (item.Children.length > 0) {
                                    <div>
                                        <a (click)="expandSubMenu(item)">
                                            <i [ngClass]="item.hide ? 'fa fa-minus' : 'fa fa-plus'"></i>
                                        </a>
                                    </div>
                                }
                            </div>
                            @if (item.hide) {
                                <span class="sub-menu">
                                    <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: item.Children }" />
                                </span>
                            }
                        </div>
                    }
                </ng-template>
                <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: mainMenu }" />
                @if (showLogonButton) {
                    <div class="dropdown-item">
                        <app-login-button class="tw-w-full" />
                    </div>
                }
            </div>
        </ng-template>
    </p-sidebar>
    <div class="menu-icon-box">
        <button
            (click)="onNavigationMenu()"
            [ngClass]="classFlag ? 'menu-icon-btn' : ''"
            class="icon search-menu-btn"
            type="button"
            aria-label="Open Main Menu"
        >
            <i class="fa fa-bars"></i>
        </button>
    </div>
</div>
