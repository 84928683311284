import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AnchorToRouterService {
  constructor(private router: Router) {}

  convertAnchorsToRouterLinks(elementRef: ElementRef): void {
    const anchorElements = elementRef.nativeElement.querySelectorAll('a[href]');

    anchorElements.forEach((anchor: HTMLAnchorElement) => {
      anchor.addEventListener('click', event => {
        const eventAnchor = event.target as HTMLAnchorElement;

        const baseUrl = document.location.protocol + '//' + document.location.host;

        const url = new URL(eventAnchor.href ?? anchor.href ?? '/', baseUrl);

        if (url && anchor.href.startsWith(baseUrl) && anchor.target !== '_blank') {
          event.preventDefault();
          // Remove # from hash
          const fragment = anchor.hash.startsWith('#') ? anchor.hash.slice(1) : anchor.hash;
          this.router.navigate([url.pathname], {
            relativeTo: this.router.routerState.root,
            fragment,
          });
        }
      });
    });
  }
}
