import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  standalone: true,
})
export class DocumentsComponent {
  private static getDocumentImage: DocumentTypes = {
    image: 'fa fa-image',
    pdf: 'fa fa-file-pdf',
    video: 'fa-regular fa-video',
    gif: 'fa fa-gif',
    word: 'fa fa-file-word',
    excel: 'fa fa-file-excel',
    powerpoint: 'fa fa-file-powerpoint',
    text: 'fa fa-file-alt',
    zip: 'fa fa-file-archive',
    audio: 'fa-regular fa-file-audio',
    default: 'fa fa-file',
  };
  @Input() documents!: any;

  getDocumentImage() {
    return DocumentsComponent.getDocumentImage[this.documents.Embed!];
  }

  getDocumentUrl() {
    const url = new URL(this.documents.File);
    if(this.getDocumentDoesDownload()){
      url.searchParams.set('download', '1');
    }
    
    return url;
  }

  getTarget(){
    if(this.documents.OnClickOption == "Open In New Tab"){
      return '_blank';
    } else {
      return '_self';
    }
  }

  getDocumentDoesDownload() {
    return this.documents.OnClickOption === 'Download';
  }

}
