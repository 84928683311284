import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(private httpService: HttpService) {}

  async pageContent(siteName: string, language: string, slug: string): Promise<Page> {
    const params = `siteName=${siteName}&language=${language}&slug=${slug}`;
    return this.httpService.get<Page>(`${environment.api}${environment.middleware}RetrievePageBySlug?${params}`);
  }

  async pageContentPreview(siteName: string, language: string, pageId: string): Promise<Page> {
    const params = `siteName=${siteName}&language=${language}&page=${pageId}`;
    return this.httpService.get<Page>(`${environment.api}${environment.middleware}RetrievePagePreview?${params}`);
  }

  /**
   * TODO! This is a placeholder for the actual implementation
   * @param data
   */
  async subscribeDetails(data: SubscribeForm): Promise<any> {
    return this.httpService.post<any>(`${environment.api}groups/api/{{App}}/Subscriptions/publicsubscribe`, data);
  }

  async getArticleById(siteName: string, language: string, articleId: string, retrieveNewsletter = false): Promise<PageContentDto | Page> {
    const params = `siteName=${siteName}&language=${language}&article=${articleId}&retrieveNewsletter=${retrieveNewsletter}`;
    return this.httpService.get<PageContentDto | Page>(`${environment.api}${environment.middleware}GetArticle?${params}`);
  }
}
