import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  async get<T extends Page | PageContentDto | LoginPageContent>(path: string, options = {}): Promise<T> {
    try {
      const response = await lastValueFrom(this.http.get(path, options));
      let proResponse: T;
      if (typeof response == 'string') {
        proResponse = JSON.parse(<string>response) as T;
      } else {
        proResponse = response as T;
      }
      return proResponse;
    } catch (httpErrorResponse: any) {
      this.handleHttpErrorResponse(httpErrorResponse);
      throw httpErrorResponse;
    }
  }

  post<T extends Response>(path: string, data: SubscribeForm): Promise<T> {
    return lastValueFrom(this.http.post(path, data))
      .then((response: object) => {
        return response as T;
      })
      .catch((httpErrorResponse: HttpErrorResponse) => {
        this.handleHttpErrorResponse(httpErrorResponse);
        throw httpErrorResponse;
      });
  }

  private handleHttpErrorResponse(httpErrorResponse: HttpErrorResponse): void {
    if (!httpErrorResponse.error && httpErrorResponse.error !== null) {
      return;
    }
    switch (httpErrorResponse.status) {
      case 500:
        break;
      case 404:
        this.router.navigate(['404']);
        break;
      case 401:
        break;
      case 403:
        break;
      case 502:
        break;
      case 0:
        break;
      default:
        break;
    }
  }
}
