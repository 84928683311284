import { isPlatformBrowser }                                                               from '@angular/common';
import { Component, ElementRef, HostListener, Inject, InjectionToken, Input, PLATFORM_ID } from '@angular/core';
import { Router }                                                                          from '@angular/router';
import { NgbModal }                                   from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil }                         from 'rxjs';
import { PageService }                                from 'src/app/shared/services/page.service';
import { Route, RouteQuery, RouteStore }              from 'src/app/store';
import { ArticlesModalComponent }                     from '../articles-modal/articles-modal.component';

@Component({
    selector: 'app-dynamic-articles-details',
    templateUrl: './dynamic-articles-details.component.html',
    styleUrls: ['./dynamic-articles-details.component.scss'],
    providers: [RouteQuery, RouteStore],
    standalone: true,
})
export class DynamicArticlesDetailsComponent {
  @Input() dynamicArticlesDetails: any;
  @Input() modalOpenType!: string;
  @Input() colSize!: number;
  selectedLanguageCode = '';
  position!: number;
  protected unsubscribe = new Subject<void>();

  constructor(
    private modalService: NgbModal,
    private pageService: PageService,
    private router: Router,
    private routeQuery: RouteQuery,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
  ) {
    this.routeQuery.route$.pipe(takeUntil(this.unsubscribe)).subscribe((route: Route) => {
      this.selectedLanguageCode = route.language.code;
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const element = this.elementRef.nativeElement;
    this.position = element.getBoundingClientRect().top + window.scrollY;
  }

  openPopUp() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const scroll = this.position;
    if (this.modalOpenType === 'modal') {
      const articlesModal = this.modalService.open(ArticlesModalComponent, { scrollable: true, size: 'xl' });
      articlesModal.componentInstance.articleId = this.dynamicArticlesDetails.Id;

      articlesModal.result.then(status => {
        if (status) {
          setTimeout(() => {
            window.scrollTo(0, scroll);
          }, 1000);
        }
      });
    } else {
      this.pageService.getArticleById(document.location.hostname, 'en', this.dynamicArticlesDetails.Id, true).then(response => {
        const newsletterResponse = response as Page;
        this.router.navigate([newsletterResponse.Slug], { fragment: 'DTX_' + this.dynamicArticlesDetails.Id });
      });
    }
  }

  formatDate(input: string): string {
    const dateObj = new Date(input);

    const year = dateObj.getUTCFullYear();
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getUTCDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  getSrc(ImageUrl: any) {
    if (ImageUrl) {
      const url = new URL(ImageUrl);
      url.searchParams.set('auto', 'true');
      // url.searchParams.set("format", "WebP");
      url.searchParams.set('cache', '8640000');
      url.searchParams.set('width', String(this.colSize * 100));
      return url.toString();
    }
    return '';
  }
}
