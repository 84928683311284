<!-- First loop is for Rows -->
@for (dynamicRows of dynamicArticles.Row; track dynamicRows) {
    <div class="row m-0 DTX_CONTENT_TYPE_DYNAMIC_ARTICLES {{ rowTypeClasses(dynamicArticles.Style) }} {{ dynamicRows.CustomCSS }}">
        <!-- Second loop is for Columns which are present inside rows -->
        @for (dynamicColumns of dynamicRows.Columns; track dynamicColumns) {
            <div class="col-xs-12 col-md-{{ 12 / dynamicRows.Columns.length }} DTX_CONTENT_TYPE_DYNAMIC_ARTICLES_COL">
                <!-- Third loop is for the component which are present inside columns -->
                @for (dynamicComp of dynamicColumns.Components; track dynamicComp) {
                    <div class="DTX_CONTENT_TYPE_DYNAMIC_ARTICLES_COMPONENT {{ rowTypeColCLasses(dynamicArticles.Style) }}">
                        @if (dynamicComp.Type === 'DynamicArticleDetails') {
                            <app-dynamic-articles-details
                                [dynamicArticlesDetails]="dynamicComp"
                                [colSize]="12 / dynamicRows.Columns.length"
                                [modalOpenType]="dynamicArticles.OpenType"
                                class="DTX_CONTENT_TYPE_DYNAMIC_ARTICLE"
                            />
                        }
                    </div>
                }
            </div>
        }
    </div>
}
