import { Component, Input } from '@angular/core';
import { DynamicArticlesDetailsComponent } from '../dynammic-articles-details/dynamic-articles-details.component';

@Component({
  selector: 'app-dynamic-articles',
  templateUrl: './dynamic-articles.component.html',
  styleUrls: ['./dynamic-articles.component.scss'],
  standalone: true,
  imports: [DynamicArticlesDetailsComponent],
})
export class DynamicArticlesComponent {
  @Input() dynamicArticles: any;

  rowTypeClasses(Style: string) {
    switch (Style) {
      case 'Card':
        return 'DTX_CONTENT_CARDS';
      default:
        return 'DTX_CONTENT_DEFAULT_STYLES';
    }
  }

  rowTypeColCLasses(Style: string) {
    switch (Style) {
      case 'Cards':
        return 'DTX_CONTENT_CARDS';
      default:
        return 'DTX_COLUMN_CARD_DEFAULT_DESIGN';
    }
  }
}
