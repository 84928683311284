import { Language } from '../types/language';

export const defaultLanguage: Language = {
  code: 'en',
  name: 'English',
};

export const languages: Language[] = [
  {
    code: 'af',
    name: 'Afrikaans',
  },
  {
    code: 'sq',
    name: 'Albanian',
  },
  {
    code: 'am',
    name: 'Amharic',
  },
  {
    code: 'ar',
    name: 'Arabic',
  },
  {
    code: 'hy',
    name: 'Armenian',
  },
  {
    code: 'as',
    name: 'Assamese',
  },
  {
    code: 'az',
    name: 'Azerbaijani (Latin)',
  },
  {
    code: 'bn',
    name: 'Bangla',
  },
  {
    code: 'ba',
    name: 'Bashkir',
  },
  {
    code: 'eu',
    name: 'Basque',
  },
  {
    code: 'bs',
    name: 'Bosnian (Latin)',
  },
  {
    code: 'bg',
    name: 'Bulgarian',
  },
  {
    code: 'yue',
    name: 'Cantonese (Traditional)',
  },
  {
    code: 'ca',
    name: 'Catalan',
  },
  {
    code: 'lzh',
    name: 'Chinese (Literary)',
  },
  {
    code: 'zh-Hans',
    name: 'Chinese Simplified',
  },
  {
    code: 'zh-Hant',
    name: 'Chinese Traditional',
  },
  {
    code: 'hr',
    name: 'Croatian',
  },
  {
    code: 'cs',
    name: 'Czech',
  },
  {
    code: 'da',
    name: 'Danish',
  },
  {
    code: 'prs',
    name: 'Dari',
  },
  {
    code: 'dv',
    name: 'Divehi',
  },
  {
    code: 'nl',
    name: 'Dutch',
  },
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'et',
    name: 'Estonian',
  },
  {
    code: 'fo',
    name: 'Faroese',
  },
  {
    code: 'fj',
    name: 'Fijian',
  },
  {
    code: 'fil',
    name: 'Filipino',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
  {
    code: 'fr',
    name: 'French',
  },
  {
    code: 'fr-ca',
    name: 'French (Canada)',
  },
  {
    code: 'gl',
    name: 'Galician',
  },
  {
    code: 'ka',
    name: 'Georgian',
  },
  {
    code: 'de',
    name: 'German',
  },
  {
    code: 'el',
    name: 'Greek',
  },
  {
    code: 'gu',
    name: 'Gujarati',
  },
  {
    code: 'ht',
    name: 'Haitian Creole',
  },
  {
    code: 'he',
    name: 'Hebrew',
  },
  {
    code: 'hi',
    name: 'Hindi',
  },
  {
    code: 'mww',
    name: 'Hmong Daw (Latin)',
  },
  {
    code: 'hu',
    name: 'Hungarian',
  },
  {
    code: 'is',
    name: 'Icelandic',
  },
  {
    code: 'id',
    name: 'Indonesian',
  },
  {
    code: 'ikt',
    name: 'Inuinnaqtun',
  },
  {
    code: 'iu',
    name: 'Inuktitut',
  },
  {
    code: 'iu-Latn',
    name: 'Inuktitut (Latin)',
  },
  {
    code: 'ga',
    name: 'Irish',
  },
  {
    code: 'it',
    name: 'Italian',
  },
  {
    code: 'ja',
    name: 'Japanese',
  },
  {
    code: 'kn',
    name: 'Kannada',
  },
  {
    code: 'kk',
    name: 'Kazakh',
  },
  {
    code: 'km',
    name: 'Khmer',
  },
  {
    code: 'tlh-Latn',
    name: 'Klingon',
  },
  {
    code: 'tlh-Piqd',
    name: 'Klingon (plqaD)',
  },
  {
    code: 'ko',
    name: 'Korean',
  },
  {
    code: 'ku',
    name: 'Kurdish (Central)',
  },
  {
    code: 'kmr',
    name: 'Kurdish (Northern)',
  },
  {
    code: 'ky',
    name: 'Kyrgyz (Cyrillic)',
  },
  {
    code: 'lo',
    name: 'Lao',
  },
  {
    code: 'lv',
    name: 'Latvian',
  },
  {
    code: 'lt',
    name: 'Lithuanian',
  },
  {
    code: 'mk',
    name: 'Macedonian',
  },
  {
    code: 'mg',
    name: 'Malagasy',
  },
  {
    code: 'ms',
    name: 'Malay (Latin)',
  },
  {
    code: 'ml',
    name: 'Malayalam',
  },
  {
    code: 'mt',
    name: 'Maltese',
  },
  {
    code: 'mi',
    name: 'Maori',
  },
  {
    code: 'mr',
    name: 'Marathi',
  },
  {
    code: 'mn-Cyrl',
    name: 'Mongolian (Cyrillic)',
  },
  {
    code: 'mn-Mong',
    name: 'Mongolian (Traditional)',
  },
  {
    code: 'my',
    name: 'Myanmar',
  },
  {
    code: 'ne',
    name: 'Nepali',
  },
  {
    code: 'nb',
    name: 'Norwegian',
  },
  {
    code: 'or',
    name: 'Odia',
  },
  {
    code: 'ps',
    name: 'Pashto',
  },
  {
    code: 'fa',
    name: 'Persian',
  },
  {
    code: 'pl',
    name: 'Polish',
  },
  {
    code: 'pt',
    name: 'Portuguese (Brazil)',
  },
  {
    code: 'pt-pt',
    name: 'Portuguese (Portugal)',
  },
  {
    code: 'pa',
    name: 'Punjabi',
  },
  {
    code: 'otq',
    name: 'Queretaro Otomi',
  },
  {
    code: 'ro',
    name: 'Romanian',
  },
  {
    code: 'ru',
    name: 'Russian',
  },
  {
    code: 'sm',
    name: 'Samoan (Latin)',
  },
  {
    code: 'sr-Cyrl',
    name: 'Serbian (Cyrillic)',
  },
  {
    code: 'sr-Latn',
    name: 'Serbian (Latin)',
  },
  {
    code: 'sk',
    name: 'Slovak',
  },
  {
    code: 'sl',
    name: 'Slovenian',
  },
  {
    code: 'so',
    name: 'Somali (Arabic)',
  },
  {
    code: 'es',
    name: 'Spanish',
  },
  {
    code: 'sw',
    name: 'Swahili (Latin)',
  },
  {
    code: 'sv',
    name: 'Swedish',
  },
  {
    code: 'ty',
    name: 'Tahitian',
  },
  {
    code: 'ta',
    name: 'Tamil',
  },
  {
    code: 'tt',
    name: 'Tatar (Latin)',
  },
  {
    code: 'te',
    name: 'Telugu',
  },
  {
    code: 'th',
    name: 'Thai',
  },
  {
    code: 'bo',
    name: 'Tibetan',
  },
  {
    code: 'ti',
    name: 'Tigrinya',
  },
  {
    code: 'to',
    name: 'Tongan',
  },
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'tk',
    name: 'Turkmen (Latin)',
  },
  {
    code: 'uk',
    name: 'Ukrainian',
  },
  {
    code: 'hsb',
    name: 'Upper Sorbian',
  },
  {
    code: 'ur',
    name: 'Urdu',
  },
  {
    code: 'ug',
    name: 'Uyghur (Arabic)',
  },
  {
    code: 'uz',
    name: 'Uzbek (Latin)',
  },
  {
    code: 'vi',
    name: 'Vietnamese',
  },
  {
    code: 'cy',
    name: 'Welsh',
  },
  {
    code: 'yua',
    name: 'Yucatec Maya',
  },
  {
    code: 'zu',
    name: 'Zulu',
  },
];
