import { Injectable } from '@angular/core';
import { Route, RouteStore } from '../../store';

@Injectable({ providedIn: 'root' })
export class RouteService {
  constructor(private routeStore: RouteStore) {}

  set(route: Route): void {
    this.routeStore.updateRoute(route);
  }

  get(): Route {
    return this.routeStore.getValue();
  }
}
