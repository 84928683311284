import { Component, Input } from '@angular/core';
import { CarouselComponent } from '../carousel/carousel.component';
import { DocumentsComponent } from '../documents/documents.component';
import { DynamicArticlesComponent } from '../dynammic-articles/dynamic-articles.component';
import { EmbedHtmlComponent } from '../embed-html/embed-html.component';
import { EmbedVideoComponent } from '../embed-video/embed-video.component';
import { GalleryComponent } from '../gallery/gallery.component';
import { ImagesComponent } from '../images/images.component';
import { RtextComponent } from '../rtext/rtext.component';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { FlipCardComponent } from '../flip-card/flip-card.component';

type ContentItems = IPageContentComponent;

@Component({
  selector: 'app-content-items',
  templateUrl: './content-items.component.html',
  styleUrls: ['./content-items.component.scss'],
  standalone: true,
  imports: [
    RtextComponent,
    ImagesComponent,
    GalleryComponent,
    EmbedVideoComponent,
    DocumentsComponent,
    DynamicArticlesComponent,
    EmbedHtmlComponent,
    SubscribeComponent,
    CarouselComponent,
    FlipCardComponent
  ],
})
export class ContentItemsComponent {
  @Input() contentItems!: ContentItems[];
  @Input() colSize!: { desktop: number; mobile: number };
}
