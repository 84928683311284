import { NgClass } from '@angular/common';
import { afterNextRender,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ColouredShapesComponent } from '../coloured-shapes/coloured-shapes.component';
import { RowComponent } from '../row/row.component';
import { TitleComponent } from '../title/title.component';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  standalone: true,
  imports: [ColouredShapesComponent, NgClass, TitleComponent, RowComponent],
})
export class ContentComponent implements OnInit, OnDestroy {
  @Input() content!: PageContentDto;
  @Input() StyleCode!: string;
  @Input() newsletterStyling = false;

  @ViewChild('contentEl') contentEl!: ElementRef<HTMLDivElement>;

  contentId!: string;
  protected unsubscribe = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<object>) {
    afterNextRender(() => {
      const flipCards = this.contentEl.nativeElement.getElementsByClassName('flip-card');
      if (flipCards.length > 0) {
        for (let i = 0; i < flipCards.length; i++) {
          flipCards[i].addEventListener('click', function () {
            flipCards[i].classList.toggle('flipped');
          });
        }
      }
    });
  }

  ngOnInit() {
    this.contentId = this.content?.Id as string;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  getPublishDate(Published: string | null) {
    if (Published) {
      const date = new Date(Published);
      return date.toLocaleDateString();
    }
    return '';
  }
}
