import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { defaultLanguage } from '../../constants/languages.constants';
import { AppStore } from './app.store';
import { Route } from './route.model';

export function createInitialState(): Route {
  return {
    language: defaultLanguage,
    param: '',
    preview: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'route',
})
export class RouteStore extends Store<Route> {
  constructor(private appStore: AppStore) {
    super(createInitialState());
  }

  updateRoute(route: Route): void {
    this._setState({
      language: route.language,
      param: route.param,
      preview: route.preview,
    });
    this.appStore.language = route.language;
  }
}
