import { NgClass }                                                               from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { Carousel, CarouselModule }                                              from 'primeng/carousel';
import { EmbedPipe } from '../../pipes/embed-pipe';
import { ImagesComponent } from '../images/images.component';
import { RowComponent } from '../row/row.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  host: { ngSkipHydration: 'true' },
  standalone: true,
  imports: [NgClass, ImagesComponent, EmbedPipe, CarouselModule, forwardRef(() => RowComponent)],
})
export class CarouselComponent {
  @Input() carouselData!: CarouselData;

  constructor() {
    // Enable scrolling on mobile devices on Carousel
    Carousel.prototype.onTouchMove = () => {};
  }
}
