import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dtxAnimations]',
  standalone: true,
})
export class AnimationDirective {
  @Input() duration = 1500;
  counterClass = 'AnimateCounterScroll';
  useCounter:boolean = false;
  isAnimation: boolean = false;
  originalContent:string = '';
  deepestContainer: any;
  private observer: IntersectionObserver | null = null;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {

  }

  ngOnInit(): void {
    this.useCounter = this.el.nativeElement.classList.contains(this.counterClass);
    if (!this.useCounter) {
      this.isAnimation = Array.from(this.el.nativeElement.classList).some((cls) => String(cls).startsWith('Animate'));
    }
    if (typeof(IntersectionObserver) != 'undefined') {
      this.onLoad();
    }
  }

  ngOnDestroy(): void {
    if (this.observer != null) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  onLoad() {
    if (this.useCounter) {
      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
             setTimeout(() => this.startAnimation(), 100);
             observer.unobserve(entry.target);
          }
        });
      });

      if (this.observer != null) {
        this.observer.observe(this.el.nativeElement);
      }
    }

    if (this.isAnimation) {
      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.renderer.addClass(this.el.nativeElement, 'StartAnimation');
          }
        });
      });
      this.observer.observe(this.el.nativeElement);
    }

  }

  startAnimation = () => {
    if (this.useCounter) {
      this.renderer.setStyle(this.el.nativeElement, 'visibility', 'visible');
        var targetNumber: number = this.el.nativeElement.innerText;
        var element = this.el.nativeElement;
        while (element.firstElementChild) {
          element = element.firstElementChild;
        }
        this.deepestContainer = element;
        this.counterAnim(0, targetNumber, this.duration);
      }
  };

  counterAnim = (start = 0, end: number, duration = 1000) => {
    let startTimestamp: number = 0;

    const step = (timestamp:number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      var newVal = Math.floor(progress * (end - start) + start);
      this.deepestContainer.innerText = newVal;
     if (progress < 1) {
      window.requestAnimationFrame(step);
     }
    };
    window.requestAnimationFrame(step);
   };

}
