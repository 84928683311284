<swiper-container
    autoplay="true"
    class="mySwiper"
    space-between="10"
    thumbs-swiper=".mySwiper2"
    #swiper
>
    @for (galleryImage of gallery.Images; track galleryImage) {
        <swiper-slide
            class="swiper-slides"
            data-swiper-autoplay="5000"
        >
            <img
                [alt]="galleryImage.AltText"
                [src]="getSrc(galleryImage.ImageUrl ?? '')"
                class="DTX_CONTENT_TYPE_GALLERY_IMAGE DTX_CONTENT_TYPE_GALLERY_IMAGE_FIT {{ galleryImage.CssClasses }}"
            />
            <a (click)="openImage(imageModal, galleryImage)" class="enlarge-icon"
            ><i class="fa-solid fa-up-right-and-down-left-from-center"></i>
            </a>
        </swiper-slide>
    }
</swiper-container>
<swiper-container
    [centerInsufficientSlides]="true"
    class="mySwiper2"
    free-mode="true"
    navigation="true"
    slides-per-view="5"
    space-between="10"
    watch-slides-progress="true"
    css-mode="true"
>
    @for (galleryImage of gallery.Images; track galleryImage) {
        <swiper-slide>
            <img
                [alt]="galleryImage.AltText"
                [src]="galleryImage.ImageUrl"
                class="DTX_CONTENT_TYPE_GALLERY_IMAGE {{ galleryImage.CssClasses }}"
            />
            <div class="bg-opacity"></div>
        </swiper-slide>
    }
</swiper-container>
<!-- In this swipper is added inside the modal -->
<ng-template #imageModal let-modal>
    <swiper-container navigation="true" space-between="10">
        @for (modalImage of enlargeImages; track modalImage) {
            <swiper-slide class="swiper-slides" data-swiper-autoplay="3000">
                <div class="modal-header">
                    <h5 class="modal-title DTX_CONTENT_IMAGE_MODAL_TITLE" id="staticBackdropLabel">
                        {{ modalImage.Name }}
                    </h5>
                    <button
                        (click)="onClose()"
                        aria-label="Close"
                        class="btn-close DTX_CONTENT_IMAGE_MODAL_CLOSE_BTN"
                        data-bs-dismiss="modal"
                        type="button"
                    ></button>
                </div>
                <div class="modal-body DTX_CONTENT_IMAGE_MODAL">
                    <img [alt]="modalImage.AltText" [src]="getSrc(modalImage.ImageUrl ?? '')" />
                </div>
            </swiper-slide>
        }
    </swiper-container>
</ng-template>
<!-- This section is used when we have to open the image in blur -->
@if (isFullDisplay) {
    <div class="overlay">
        <div class="popup">
            <a (click)="hide()">
                <div class="DTX_CONTENT_IMAGE_MODAL popup-img">
                    <p-button icon="fa fa-chevron-left" class="left-button" [link]="true" (click)="displayLeft($event)"></p-button>
                    <img [alt]="gallery.Images[displayIndex].AltText" [src]="getSrc(gallery.Images[displayIndex].ImageUrl ?? '')" />
                    <p-button icon="fa fa-chevron-right" class="right-button" [link]="true" (click)="displayRight($event)"></p-button>
                </div>
            </a>
        </div>
    </div>
}
