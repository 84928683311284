import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PageTypeService {
  private pageType?: boolean;

  setPageType(pageType: boolean) {
    this.pageType = pageType;
  }

  getPageType(): boolean {
    return this.pageType || false;
  }
}
