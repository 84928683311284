import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Route } from './route.model';
import { RouteStore } from './route.store';

@Injectable({
  providedIn: 'root',
})
export class RouteQuery extends Query<Route> {
  route$ = this.select(state => state);
  language$ = this.select(state => state.language);

  constructor(protected routeStore: RouteStore) {
    super(routeStore);
  }

  get(): Route {
    return this.routeStore.getValue();
  }
}
