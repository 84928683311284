import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-title',
    templateUrl: './title.component.html',
    styleUrls: ['./title.component.scss'],
    standalone: true,
})
export class TitleComponent {
  @Input() contentTitle!: string;

  getTitle(): string {
    return this.contentTitle;
  }
}
