<div class="shapes-box rb-position-shapes left-side-shapes-box">
    <div class="shapes-box-full flex-justifyCenter-itemsCenter">
        <div class="flex-height-width-full">
            <div class="shapes-box-3 w-100 shapes-bg-orange"></div>
            <div class="top-bottom-two-shapes w-100">
                <div class="shapes-box-1 shapes-bg-green h-50"></div>
                <div class="shapes-box-2 shapes-bg-blue h-50"></div>
            </div>
        </div>
    </div>
</div>
<div class="shapes-box rc-position-shapes right-side-shapes-box">
    <div class="shapes-box-full">
        <div class="flex-height-width-full">
            <div class="top-bottom-two-shapes w-100">
                <div class="shapes-box-1 shapes-bg-red h-50"></div>
                <div class="shapes-box-2 shapes-bg-orange h-50"></div>
            </div>
        </div>
    </div>
</div>
